import { createStore } from "vuex";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, addDoc, doc } from "firebase/firestore";
import { db, collections} from "../firebaseConfig";

const paymentMethod = {
	ZELLE: "zelle",
	CREDIT_CARD: "credit_card",
};

const stripeFees = {
	MDR: 0.961,
	TRXN_FEE: 3.5,
};

Object.freeze(paymentMethod);
Object.freeze(stripeFees);

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
	if (user) {
		store.commit("setLoggedInUser", user);
		store.commit("setIsLoggedIn", true);
		store.dispatch("fetchProfile", user);
	} 
});

export const store =  createStore({
	state: {
		pageTitle: "",
		messages: null,
		loggedInUser: {},
		userProfile: {},
		stripeClientSecret: "",
		myOrders: null,
		pageLoading: true,
		initialized:false,
		imgPlaceholder: "https://dummyimage.com/500x500/ddd/f1f1f1&text=add-image",
		profileDoc: "",
		isLoggedIn:false,
		isReservationFormBusy: false,
	},
	mutations: {
		setStripeClientSecret(state, val){
			state.stripeClientSecret = val;
		},
		setIsReservationFormBusy(state, val){
			state.isReservationFormBusy = val;
		},
		setUserProfile(state, val) {
			state.userProfile = val;
		},
		setProfileDoc(state, val) {
			state.profileDoc = val;
		},
		setPageTitle(state, val){
			state.pageTitle = val;
		},
		setIsLoggedIn(state, val){
			state.isLoggedIn = val;
		},
		setPageLoading(state, val){
			state.pageLoading = val;
		},
		setLoggedInUser(state, val){
			state.loggedInUser = val;
		},
	},
	actions: {
		logOut(){
			const auth = getAuth();
			signOut(auth).then(() => {
				window.location.href = "/rotarytt/moves-for-cop";
			}).catch((error) => {
				console.log(error)
			});
		},
		async fetchProfile({ commit }, user){

			try{
				//Load profile
				const profileDocRef = doc(db, collections.PROFILES, user.uid);
				onSnapshot(profileDocRef, (doc) => {
					commit("setUserProfile", {id: doc.id, ...doc.data()});
					commit("setProfileDoc", doc.id);
				});
			}catch(error){
				console.log(error);
			}
		},
		async updateStripePaymentIntent({ state, commit }, { stripePaymentAmount } ){
			if(state.stripeClientSecret){
				commit("setIsReservationFormBusy", true);
				const [paymentIntent] = state.stripeClientSecret.split("_secret_");
				await fetch("https://app.socaislands.com/api/stripe/update", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						stripeAmount: stripePaymentAmount,
						paymentIntent: paymentIntent,
					})
				})
				.then(response => response.json())
				.then(data => {
					commit("setIsReservationFormBusy", false);
					return data;
				});
			}
		},
		async sendNewEmail(emailData){
			addDoc(collection(db, collections.EMAIL_MESSAGES), emailData);
		},

		openExternalLink(props){
			console.log(props)
			//window.location.href = props.url;
		},
	},
	modules: {},
});
