import { createApp } from 'vue';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import router from "./router";
import { store } from "./store";
import App from './App.vue';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//createApp(App).use(router).mount('#app');

let app;
const auth = getAuth();
onAuthStateChanged(auth, () => {
	if (!app) {
		app = createApp(App);
		app.use(store).use(router).mount("#app");
	}
})
