import { initializeApp } from "firebase/app";

import { 
	getFirestore, 
} from "firebase/firestore";

import { 
	getAuth, 
	signOut,
	createUserWithEmailAndPassword, 
	signInWithEmailAndPassword, 
	onAuthStateChanged,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAqfTsxq3Ub6Y-glm0TOnU8pmfmmGRJE9s",
	authDomain: "app-soca-islands.firebaseapp.com",
	databaseURL: "https://app-soca-islands.firebaseio.com",
	projectId: "app-soca-islands",
	storageBucket: "app-soca-islands.appspot.com",
    messagingSenderId: "18088136934",
    appId: "1:18088136934:web:75851d00d16b6833042b27",
    measurementId: "G-6ZHCJ8FV41"
}

const firebase = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const authStateChanged = onAuthStateChanged;

const emailTemplates = {
	NEW_BOOKING: "ev_booking_received",
	NEW_PAYMENT: "ev_new_payment",
	CONFIRM_BOOKING: "ev_booking_confirmed",
	FAILED_BOOKING: "ev_booking_failed",
	CONFIRM_EMAIL: "email_address_confirmed",
	NEW_ACCOUNT: "welcome_to_soca_islands",
}

//collections
const collections = {
	PROMOTERS: "ev_promoters",
	CONTACTS: "si_address_book",
	EMAIL_MESSAGES: "si_app_email_messages",
	EVENTS: "ev_events",
	FCM_TOKENS: "si_fcmtokens",
	ORDERS: "ev_bookings",
	PAYMENTS: "ev_transactions",
	EVENT_SETTINGS: "ev_event_settings",
	PAYMENT_OPTIONS: "payment_options",
	PAYOUT_METHODS: "payout_methods",
	PROFILES: "si_users",
	MESSAGES: "si_messages",
}

export {
	auth,
	authStateChanged,
	db,
	collections,
	createUserWithEmailAndPassword,
	emailTemplates,
	firebase,
	signInWithEmailAndPassword,
	signOut,
}