<template>
	<div class="row">
		<div class="col-12" align="right">
			<p class="border-1 border-bottom"><a href="/profile" class="text-decoration-none text-secondary"><i class="bi bi-person"></i> My profile</a></p>
		</div>
	</div>
	<div class="row">
		<div class="col-12 p-4 p-lg-5 pt-lg-4">
			<router-view />
		</div>
	</div>
	<div class="row">
		<div class="col-12 p-4 p-lg-5" align="right">
			<hr/>
			<p>
				<a href="mailto:explore@socaislands.com" 
				class="text-decoration-none text-secondary">
					<i class="bi bi-envelope"></i> explore@socaislands.com
				</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
.pointer{
	cursor: pointer;
}
</style>
