import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";

const routes = [
	{
		path: "/",
		name: "EventIndex",
		component: () =>
			import(/* webpackChunkName: "event" */ "@/views/Event.vue"),
		meta: {
			requiresAuth: false,
			title: "Event Information - Soca Islands",
		}
	},
	{
		path: "/:promoter_id/:event_id",
		name: "Event",
		component: () =>
			import(/* webpackChunkName: "event" */ "@/views/Event.vue"),
		meta: {
			requiresAuth: false,
			title: "Event Information - Soca Islands",
		}
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "auth" */ "@/views/Login.vue"),
		meta: {
			requiresAuth: false,
			title: "Login - Soca Islands",
		}
	},
	{
		path: "/signup",
		name: "Signup",
		component: () =>
			import(/* webpackChunkName: "auth" */ "@/views/Signup.vue"),
		meta: {
			requiresAuth: false,
			title: "Signup - Soca Islands",
		}
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () =>
			import(/* webpackChunkName: "auth" */ "@/views/ResetPassword"),
		meta: {
			requiresAuth: false,
			title: "Reset Your Password - Soca Islands",
		}
	},
	{
		path: "/profile",
		name: "Profile",
		component: () =>
			import(/* webpackChunkName: "profile" */ "@/views/Profile"),
		meta: {
			requiresAuth: true,
			title: "My Profile - Soca Islands",
		}
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0, behavior: "auto" }
		}
	},
	linkActiveClass: "mobile-menu-link-active",
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
	const auth = getAuth();
	const currentUser = auth.currentUser;
	const windowTitle = to.meta?.title ? to.meta.title : "Soca Islands";

	window.document.title = windowTitle;

    if (requiresAuth && !currentUser) {
        next('/login?from='+to.path)
    } else if (requiresAuth && currentUser) {
        next()
    } else {
        next()
    }
})

export default router;